import * as React from "react";
import { DataContext } from "../../../context/DataContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import Badge from "@mui/material/Badge";

function TodaysBets() {
  const { isDev } = React.useContext(DataContext);
  const [selectedDate, setSelectedDate] = React.useState(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [todaysBets, setTodaysBets] = React.useState([]);
  const [finalScores, setFinalScores] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getTodaysBets = async (date, finals) => {
    const devURL = `http://localhost:3001/elite-edge-advisors/get-all-bets`;
    const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/get-all-bets"}`;
    await fetch(isDev ? devURL : url)
      .then((res) => res.json())
      .then((data) => {
        const { ourBets } = data;
        const filtered = ourBets.map((bet) => {
          return Object.fromEntries(
            Object.entries(bet).filter(([_, v]) => v != null)
          );
        });
        const todaysBets = filtered
          .filter(
            (bet) =>
              moment.utc(bet.date).format("YYYY-MM-DD") ===
              moment(date).format("YYYY-MM-DD")
          )
          .map((unParsedBet) => {
            const keys = Object.keys(unParsedBet).filter((key) =>
              key.includes("bet")
            );
            const bets = keys.map((key) => unParsedBet[key]);
            return bets.map((bet) => JSON.parse(bet));
          });
        setFinalScores(finals);
        todaysBets.length > 0
          ? setTodaysBets(...todaysBets)
          : setTodaysBets([]);
        console.log(
          "🚀 ~ file: TodaysBets.js:57 ~ .then ~ todaysBets:",
          todaysBets
        );
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getTheData(moment().subtract(1, "days").format("YYYY-MM-DD"));
  }, []);

  const getFinalScores = async (date) => {
    return await fetch(
      `https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/scoreboard?dates=${moment(
        date
      ).format("YYYYMMDD")}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.events;
      });
  };

  const dates = [0, 1, 2, 3, 4, 5].map((x) =>
    moment(selectedDate).subtract(x, "days").format("YYYY-MM-DD")
  );

  const getLinescore = (linescore) => {
    if (linescore?.length > 8) {
      return linescore?.map((x) => x.value);
    } else if (linescore?.length < 9) {
      const bets = new Array(9 - linescore?.length).fill("-");
      bets.unshift(...linescore?.map((x) => x.value));
      return bets;
    } else {
      return linescore?.map((x) => x.value);
    }
  };

  React.useEffect(() => {
    getTheData(selectedDate);
  }, [selectedDate]);

  const getTheData = async (date, leftOrRight) => {
    console.log("🚀 ~ file: To##################1 ~ getTheData ~ date:", date);
    setFinalScores([]);
    setTodaysBets([]);
    const finals = await getFinalScores(date);
    await getTodaysBets(date, finals);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: 20,
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            flexwrap: "wrap",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component="a"
            sx={{ paddingLeft: 2, paddingRight: 2, height: 50 }}
            onClick={() => {
              setSelectedDate(
                moment(selectedDate).subtract(1, "days").format("YYYY-MM-DD")
              );
            }}
          >
            {" < "}
          </Button>
          {dates.reverse().map((date) => (
            <Button
              color={date === selectedDate ? "primary" : "secondary"}
              variant="contained"
              size="large"
              component="a"
              sx={{ paddingLeft: 2, paddingRight: 2, height: 50 }}
              onClick={() => {
                getTheData(date);
              }}
            >
              {moment(date).format("MMM Do")}
            </Button>
          ))}
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component="a"
            sx={{ paddingLeft: 2, paddingRight: 2, height: 50 }}
            disabled={
              selectedDate === moment().subtract(1, "days").format("YYYY-MM-DD")
            }
            onClick={() => {
              setSelectedDate(
                moment(selectedDate).add(1, "days").format("YYYY-MM-DD")
              );
            }}
          >
            {" > "}
          </Button>
        </div>
      </div>
      <Typography
        component="h2"
        variant="h4"
        color="primary"
        style={{ marginTop: 20 }}
      >
        Bets For {moment(selectedDate).format("MMM Do")}
      </Typography>
      <Grid container style={{}}>
        {!isLoading &&
          todaysBets.map((bet) => {
            const score = finalScores?.find((x) => x.id === bet.espnGameId);
            const awayTeamScore = parseInt(
              score?.competitions[0].competitors[1].score
            );
            const homeTeamScore = parseInt(
              score?.competitions[0].competitors[0].score
            );
            const homeTeamLineScore = getLinescore(
              score?.competitions[0].competitors[0].linescores
            );
            const awayTeamLineScore = getLinescore(
              score?.competitions[0].competitors[1].linescores
            );
            const totalScore = homeTeamScore + awayTeamScore;
            if (bet.ourBet?.type === "overUnder") {
              const won =
                (bet.ourBet?.outcome === "over" &&
                  totalScore > bet.ourBet?.details.point) ||
                (bet.ourBet?.outcome === "under" &&
                  totalScore < bet.ourBet?.details.point);
              return (
                <Grid
                  item
                  key={bet.espnGameId}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{
                    fontFamily: "Play",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    border: won ? "1px solid green" : "1px solid red",
                    backgroundColor: won ? "green" : " red",
                    padding: "25px 0px",
                    color: "white",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.awayTeam}
                  </p>
                  {" vs "}
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.homeTeam}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.ourBet?.outcome} {bet.ourBet?.details.point} (
                    {bet.ourBet?.details.price > 0 ? "+" : null}
                    {bet.ourBet?.details.price})
                  </p>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.details?.type.shortDetail.split(" - ")[1]}
                    </p>
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.ourBet?.note}
                    </p>
                  </div>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ textAlign: "left", margin: 0, marginTop: 2 }}>
                        {bet.awayTeamAbbr}
                      </p>
                      <p style={{ textAlign: "left", margin: 0, marginTop: 2 }}>
                        {bet.homeTeamAbbr}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Play",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {awayTeamLineScore.map((x) => (
                          <p style={{ margin: 0, marginTop: 2, marginLeft: 8 }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {score?.competitions[0].competitors[1].score}
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "Play",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {homeTeamLineScore.map((x) => (
                          <p style={{ margin: 0, marginTop: 2, marginLeft: 8 }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {score?.competitions[0].competitors[0].score}
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            } else if (bet.ourBet?.type === "spread") {
              const winner =
                bet.ourBet?.outcome ===
                score?.competitions[0].competitors[1].team.displayName
                  ? "away"
                  : "home";
              const won =
                winner === "home"
                  ? homeTeamScore + bet.ourBet?.details.point > awayTeamScore
                  : homeTeamScore + bet.ourBet?.details.point < awayTeamScore;
              return (
                <Grid
                  item
                  key={bet.espnGameI}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{
                    fontFamily: "Play",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    border: won ? "1px solid green" : "1px solid red",
                    backgroundColor: won ? "green" : " red",
                    padding: "25px 0px",
                    color: "white",
                  }}
                  onClick={() => {}}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.ourBet?.outcome}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.ourBet?.details.point > 0 ? "+" : null}
                    {bet.ourBet?.details.point} (
                    {bet.ourBet?.details.price > 0 ? "+" : null}
                    {bet.ourBet?.details.price}){" "}
                  </p>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.details?.type.shortDetail.split(" - ")[1]}
                    </p>
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.ourBet?.note}
                    </p>
                  </div>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ textAlign: "left", margin: 0, marginTop: 2 }}>
                        {bet.awayTeamAbbr}
                      </p>
                      <p style={{ textAlign: "left", margin: 0, marginTop: 2 }}>
                        {bet.homeTeamAbbr}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Play",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {awayTeamLineScore.map((x) => (
                          <p style={{ margin: 0, marginTop: 2, marginLeft: 8 }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {score?.competitions[0].competitors[1].score}
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "Play",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {homeTeamLineScore.map((x) => (
                          <p style={{ margin: 0, marginTop: 2, marginLeft: 8 }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {score?.competitions[0].competitors[0].score}
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            } else {
              const winner =
                bet.ourBet?.outcome ===
                score?.competitions[0].competitors[1].team.displayName
                  ? "away"
                  : "home";
              const won =
                winner === "home"
                  ? homeTeamScore > awayTeamScore
                  : homeTeamScore < awayTeamScore;
              return (
                <Grid
                  item
                  key={bet.espnGameI}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{
                    fontFamily: "Play",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    border: won ? "1px solid green" : "1px solid red",
                    backgroundColor: won ? "green" : " red",
                    padding: "25px 0px",
                    color: "white",
                  }}
                  onClick={() => {}}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.ourBet?.outcome}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    ML {bet.ourBet?.details > 0 ? "+" : null}
                    {bet.ourBet?.details}
                  </p>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.details?.type.shortDetail.split(" - ")[1]}
                    </p>
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.ourBet?.note}
                    </p>
                  </div>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ textAlign: "left", margin: 0, marginTop: 2 }}>
                        {bet.awayTeamAbbr}
                      </p>
                      <p style={{ textAlign: "left", margin: 0, marginTop: 2 }}>
                        {bet.homeTeamAbbr}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Play",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {awayTeamLineScore.map((x) => (
                          <p style={{ margin: 0, marginTop: 2, marginLeft: 8 }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {score?.competitions[0].competitors[1].score}
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "Play",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {homeTeamLineScore.map((x) => (
                          <p style={{ margin: 0, marginTop: 2, marginLeft: 8 }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {score?.competitions[0].competitors[0].score}
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            }
          })}
      </Grid>
    </div>
  );
}

export default TodaysBets;
