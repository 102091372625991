import * as React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "../components/Typography";
import AppFooter from "../views/AppFooter";
import AppAppBar from "../views/AppAppBar";
import AppForm from "../views/AppForm";
import { email, required } from "../form/validation";
import RFTextField from "../form/RFTextField";
import FormButton from "../form/FormButton";
import FormFeedback from "../form/FormFeedback";
import { DataContext } from "../../context/DataContext";
import { useNavigate, useParams } from "react-router-dom";

function SignIn() {
  const navigate = useNavigate();
  const { username, password } = useParams();
  const [sent, setSent] = React.useState(false);
  const { isLoggedIn, setIsLoggedIn, account, setAccount, isDev } =
    React.useContext(DataContext);

  const validate = (values) => {
    console.log("🚀 ~ file: SignIn.js:24 ~ validate ~ values:", values);
    const combinedValues = {
      email: username ? username : values.email,
      password: password ? password : values.password,
    };
    const errors = required(["email", "password"], combinedValues);
    if (username && password) return errors;
    console.log(
      "🚀 ~ file: SignIn.js:27 ~ validate ~ username && password:",
      username && password
    );
    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = async (data) => {
    try {
      const account = {
        email: username ? username : data.email,
        password: password ? password : data.password,
      };
      const devUrl = `${"http://localhost:3001/elite-edge-advisors/sign-in"}`;
      const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/sign-in"}`;
      const response = await fetch(isDev ? devUrl : url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          account,
        }),
      });
      if (response.status === 400) {
        throw new Error("Wrong email and or password");
      } else {
        const { account } = await response.json();
        setAccount({
          ...account,
          subscriptions: JSON.parse(account.subscriptions),
        });
        setIsLoggedIn(true);
        if (account.admin) {
          navigate("/admin");
        } else {
          navigate("/account");
        }
        setSent(false);
      }
    } catch (error) {
      alert(error);
      setSent(false);
    }
  };

  React.useEffect(() => {
    if (username && password) handleSubmit();
  }, []);

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign In
          </Typography>
          <Typography variant="body2" align="center">
            {"Not a member yet? "}
            <Link href="/sign-up/" align="center" underline="always">
              Sign Up here
            </Link>
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit: handleSubmit2, submitting }) => (
            <Box
              component="form"
              onSubmit={handleSubmit2}
              noValidate
              sx={{ mt: 6 }}
            >
              <Field
                autoComplete="email"
                autoFocus
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                size="large"
                defaultValue={username}
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="password"
                autoComplete="current-password"
                label="Password"
                type="password"
                margin="normal"
                defaultValue={password}
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting || sent}
                size="large"
                color="secondary"
                fullWidth
              >
                {submitting || sent ? "In progress…" : "Sign In"}
              </FormButton>
            </Box>
          )}
        </Form>
        <Typography align="center">
          <Link underline="always" href="/forgot-password/">
            Forgot password?
          </Link>
        </Typography>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default SignIn;
