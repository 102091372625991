import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { Field, Form, FormSpy } from "react-final-form";
import { DataContext } from "../../../context/DataContext";
import Typography from "../../components/Typography";
import AppFooter from "../../views/AppFooter";
import AppAppBar from "../../views/AppAppBar";
import AppForm from "../../views/AppForm";
import { email, required } from "../../form/validation";
import RFTextField from "../../form/RFTextField";
import FormButton from "../../form/FormButton";
import FormFeedback from "../../form/FormFeedback";
import { useNavigate } from "react-router-dom";
import Orders from "./Orders";
import TodaysBets from "./TodaysBets";

function Account() {
  const navigate = useNavigate();

  const [sent, setSent] = React.useState(false);
  const { isLoggedIn, setIsLoggedIn, account, setAccount, isDev } = React.useContext(DataContext);

  React.useEffect(() => {
    // if (!account.admin) {
    //   navigate("/");
    // }
  }, []);

  const handleSubmit = async (data) => {
    try {
      console.log("🚀 ~ file: SignUp.js:35 ~ handleSubmit ~ event:", data);
      const account = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: data.password,
        subscriptions: [],
      };
      const devUrl = `${"http://localhost:3001/elite-edge-advisors/add-account"}`;
      const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/add-account"}`;
      const response = await fetch(isDev ? devUrl : url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          account,
        }),
      });
      if (response.status === 400) {
        throw new Error("Account already exists");
      } else {
        const { account } = await response.json();
        console.log("🚀 ~ file: SignUp.js:61 ~ handleSubmit ~ data:", account);
        setAccount(account);
        navigate("/account");
        setSent(false);
      }
    } catch (error) {
      alert(error);
      setSent(false);
    }
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <TodaysBets />
      {/* <Orders /> */}
      <AppFooter />
    </React.Fragment>
  );
}

export default Account;
