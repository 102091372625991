import * as React from "react";
import { DataContext } from "../../../context/DataContext";
import AppFooter from "../../views/AppFooter";
import AppAppBar from "../../views/AppAppBar";
import { useNavigate } from "react-router-dom";
import TodaysBets from "./TodaysBets";

function Account() {
  const navigate = useNavigate();

  const { isLoggedIn, setIsLoggedIn, account, setAccount, isDev } =
    React.useContext(DataContext);

  React.useEffect(() => {}, []);

  return (
    <React.Fragment>
      <AppAppBar />
      <TodaysBets />
      <AppFooter />
    </React.Fragment>
  );
}

export default Account;
