import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "../components/Typography";
import { DataContext } from "../../context/DataContext";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  const { isLoggedIn } = React.useContext(DataContext);
  const products = [
    {
      price: 25,
      type: "day",
      length: 1,
      link: "https://buy.stripe.com/3cs6ptgE9bxb3NS9AD",
    },
    {
      price: 75,
      type: "days",
      length: 7,
      link: "https://buy.stripe.com/6oE0151Jf8kZfwA6os",
    },
    {
      price: 125,
      type: "month",
      length: 1,
      link: "https://buy.stripe.com/eVa0159bH9p3fwAcMM",
    },
    {
      price: 200,
      type: "months",
      length: 2,
      link: "https://buy.stripe.com/cN2eVZdrX9p3dos289",
    },
    {
      price: 1000,
      type: "months",
      length: 12,
      link: "https://buy.stripe.com/9AQ9BFdrX6cReswdQS",
    },
  ];
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 5, mb: 15, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src="/pics/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          {products.map((product, index) => (
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src="/pics/1month.png"
                  alt="clock"
                  sx={{ height: 55 }}
                />
                <Typography variant="h6" sx={{ my: 2 }}>
                  {product.length} {product.type} of picks
                </Typography>
                <Typography
                  variant="h5"
                  style={{ textAlign: "center", marginBottom: 10 }}
                >
                  ${product.price}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href={!isLoggedIn ? "/sign-up/" : product.link}
                >
                  Subscribe
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
