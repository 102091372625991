import * as React from "react";
import { DataContext } from "../../../context/DataContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

function TodaysBets() {
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn, account, setAccount, isDev } = React.useContext(DataContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [bestBets, setBestBets] = React.useState([]);
  const [todaysBets, setTodaysBets] = React.useState([]);
  const [coversDotComPredictions, setCoversDotComPredictions] = React.useState([]);
  const [selectedBets, setSelectedBets] = React.useState([]);
  const [note, setNote] = React.useState([]);
  const [addNote, setAddNote] = React.useState(false);
  const [noteID, setNoteID] = React.useState(null);
  const [hasBeenSent, setHasBeenSent] = React.useState(false);
  const [finalScores, setFinalScores] = React.useState([]);
  const colors = {
    darkRed: "#D61F1F",
    lightRed: "#E03C32",
    yellow: "#FFD301",
    lightGreen: "#7BB662",
    midGreen: "#639754",
    darkGreen: "#006B3D",
  };
  const getTodaysBets = async () => {
    const devURL = `http://localhost:3001/elite-edge-advisors/get-all-bets`;
    const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/get-all-bets"}`;
    const scores = await getFinalScores(moment().format("YYYYMMDD"));
    setFinalScores(scores);
    await fetch(isDev ? devURL : url)
      .then((res) => res.json())
      .then((data) => {
        const { ourBets } = data;
        const filtered = ourBets?.map((bet) => {
          return Object.fromEntries(Object.entries(bet).filter(([_, v]) => v != null));
        });
        const allBets = filtered
          .filter(
            (bet) => moment.utc(bet.date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          )
          .map((unParsedBet) => {
            setHasBeenSent(unParsedBet.has_been_sent);
            const keys = Object.keys(unParsedBet).filter((key) => key.includes("bet"));
            const bets = keys.map((key) => unParsedBet[key]);
            return bets.map((bet) => JSON.parse(bet));
          });
        allBets.length > 0 && setTodaysBets(...allBets);
      });

    await fetch(
      "https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/coversDotComPredictor"
    )
      .then((res) => res.json())
      .then((data) => {
        setCoversDotComPredictions(data?.results);
      });
  };
  const getAllGamesForToday = async () => {
    const parlayURLDev = `http://localhost:3001/parlays`;
    const parlayURL = `https://sheline-art-website-api.herokuapp.com/parlays`;
    await fetch(isDev ? parlayURLDev : parlayURL)
      .then((res) => res.json())
      .then((data) => {
        console.log("🚀 ~ .then ~ data:", data);
        setBestBets([
          ...data.games.filter((x) => x !== null),
          ...data.nhlGames?.filter((x) => x !== null),
          ...data.nflGames?.filter((x) => x !== null),
          ...data.nbaGames?.filter((x) => x !== null),
          ...data.ncaaFootball?.filter((x) => x !== null),
          ...data.ncaaBasketball?.filter((x) => x !== null),
        ]);
        const bets = data.games.map((game, idx) => {
          return {
            ourBet: {
              tempID: idx,
              outcome: game.awayTeam.name,
              type: "moneyline",
              note: "",
              details: game.awayTeam.odds.moneyline,
            },
            ...game,
          };
        });
        // setSelectedBets([
        //   ...selectedBets,
        //   {
        //     ourBet: {
        //       tempID: selectedBets.length + 1,
        //       outcome: bet.awayTeam.name,
        //       type: "moneyline",
        //       note: "",
        //       details: bet.awayTeam.odds.moneyline,
        //     },
        //     ...bet,
        //   },
        // ])
      });
  };

  const getFinalScores = async (date) => {
    return await fetch(
      `https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/scoreboard?dates=${moment(
        date
      ).format("YYYYMMDD")}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.events;
      });
  };
  const getEverything = async () => {
    await getAllGamesForToday();
    await getTodaysBets();
    setIsLoading(false);
  };
  React.useEffect(() => {
    getEverything();
  }, []);

  React.useEffect(() => {}, [selectedBets]);

  const handleSendBets = async () => {
    try {
      const devUrl = `${"http://localhost:3001/elite-edge-advisors/send-todays-bet"}`;
      const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/send-todays-bet"}`;
      const response = await fetch(isDev ? devUrl : url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: null,
      });
      if (response.status === 400) {
        throw new Error("Error saving bets");
      } else {
        alert("Bets sent");
        // navigate("/account");
      }
    } catch (error) {
      alert(error);
    }
  };
  const getOverUnderItems = (type, point, bet) => {
    const thePoint = !point ? 0 : point;
    const points = [];
    if (thePoint === 0) {
      for (let i = thePoint - 50; i <= thePoint + 50; i += 0.5) {
        points.push(i);
      }
    } else {
      for (let i = thePoint - 16; i <= thePoint + 16; i += 0.5) {
        points.push(i);
      }
    }
    return points.reverse().map((x) => (
      <MenuItem
        onClick={() =>
          setSelectedBets([
            ...selectedBets,
            {
              ourBet: {
                tempID: selectedBets.length + 1,
                note: "",
                outcome: type,
                type: "overUnder",
                details: {
                  name: type === "over" ? "Over" : "Under",
                  price: -100,
                  point: x,
                },
              },
              ...bet,
            },
          ])
        }
        value={x}>
        {x}
      </MenuItem>
    ));
  };
  const getSpreadItems = (type, point, bet) => {
    const points = [];
    for (let i = point - 15; i <= point + 18; i += 0.5) {
      points.push(i);
    }
    return points.map((x) => (
      <MenuItem
        onClick={() => {
          setSelectedBets([
            ...selectedBets,
            {
              ourBet: {
                tempID: selectedBets.length + 1,
                note: "",
                outcome: type === "away" ? bet.awayTeam.name : bet.homeTeam.name,
                type: "spread",
                details: {
                  name: type === "over" ? "Over" : "Under",
                  price: -100,
                  point: x,
                },
              },
              ...bet,
            },
          ]);
        }}
        value={x}>
        {x}
      </MenuItem>
    ));
  };
  const handleSaveBets = async () => {
    const bets = selectedBets.map((bet) => {
      return {
        awayTeam: bet.awayTeam.name,
        homeTeam: bet.homeTeam.name,
        awayTeamAbbr: bet.awayTeam.abbr,
        homeTeamAbbr: bet.homeTeam.abbr,
        date: bet.date,
        espnGameId: bet.espnGameId,
        ourBet: bet.ourBet,
        details: bet.details,
      };
    });

    try {
      const devUrl = `${"http://localhost:3001/elite-edge-advisors/set-todays-bet"}`;
      const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/set-todays-bet"}`;
      const response = await fetch(isDev ? devUrl : url, {
        method: todaysBets.length > 0 ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selectedBets: bets,
          todaysBetsLength: todaysBets.length,
        }),
      });
      if (response.status === 400) {
        throw new Error("Error saving bets");
      } else {
        getTodaysBets();
        setSelectedBets([]);
        // navigate("/account");
      }
    } catch (error) {
      console.log("🚀 ~ file: TodaysBets.js:209 ~ handleSaveBets ~ error:", error);
      alert(error);
    }
  };

  const getLinescore = (linescore) => {
    if (linescore?.length > 8) {
      return linescore.map((x) => x.value);
    } else if (linescore?.length < 9) {
      const bets = new Array(9 - linescore?.length).fill("-");
      bets.unshift(...linescore.map((x) => x.value));
      return bets;
    } else {
      const bets = new Array(9).fill("-");
      return bets;
    }
  };

  return (
    <div
      style={{
        fontFamily: "'Baloo Bhaijaan', cursive",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        textAlign: "center",
      }}>
      <div
        style={{
          fontFamily: "'Baloo Bhaijaan', cursive",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignContent: "center",
          textAlign: "center",
        }}>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          sx={{ width: 300, marginBottom: 2 }}
          onClick={() => {
            getTodaysBets();
          }}>
          Refresh
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          sx={{ width: 300, marginBottom: 2 }}
          onClick={() => {
            navigate("/previous-bets");
          }}>
          Previous Bets
        </Button>
      </div>
      <Typography
        component="h2"
        variant="h4"
        color="primary"
        style={{ fontFamily: "'Baloo Bhaijaan', cursive", marginTop: 20 }}>
        Bets For Today
      </Typography>
      {/* {!hasBeenSent && todaysBets.length > 0 && (
        <div>
          <Typography component="h2" variant="h4" style={{ fontFamily: "'Baloo Bhaijaan', cursive", marginTop: 0, color: "red" }}>
            HAVE NOT BEEN SENT OUT
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component="a"
            sx={{ width: 300, marginBottom: 2 }}
            onClick={() => {
              handleSendBets();
            }}>
            Send Today's Bets
          </Button>
        </div>
      )} */}
      <Grid container style={{ fontFamily: "'Baloo Bhaijaan', cursive" }}>
        {todaysBets?.length > 0 &&
          todaysBets.map((bet) => {
            const score = finalScores?.find((x) => x.id === bet.espnGameId);
            const awayTeamScore = parseInt(score?.competitions[0].competitors[1].score);
            const homeTeamScore = parseInt(score?.competitions[0].competitors[0].score);
            const homeTeamLineScore = getLinescore(
              score?.competitions[0].competitors[0].linescores
            );
            const awayTeamLineScore = getLinescore(
              score?.competitions[0].competitors[1].linescores
            );
            const shortDetail = bet.details?.type?.shortDetail;
            const time =
              bet.details?.type?.name === "STATUS_SCHEDULED"
                ? shortDetail.split("- ")[1].split(" E")[0]
                : "";
            const momentTime =
              bet.details?.type?.name === "STATUS_SCHEDULED"
                ? moment(time, "h:mm a").subtract(1, "hour").format("h:mm a") + " CST"
                : "";
            const totalScore = homeTeamScore + awayTeamScore;
            if (bet.ourBet?.type === "overUnder") {
              const won =
                (bet.ourBet?.outcome === "over" && totalScore > bet.ourBet?.details.point) ||
                (bet.ourBet?.outcome === "under" && totalScore < bet.ourBet?.details.point);
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{
                    fontFamily: "'Baloo Bhaijaan', cursive",

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    border: won ? "1px solid green" : "1px solid red",
                    backgroundColor: won ? "green" : "red",
                    padding: "25px 0px",
                    color: "white",
                  }}>
                  <p
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      margin: 0,
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}>
                    {bet.awayTeam}
                  </p>
                  {" vs "}
                  <p
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      margin: 0,
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}>
                    {bet.homeTeam}
                  </p>
                  <p
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      margin: 0,
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}>
                    {bet.ourBet?.outcome} {bet.ourBet?.details.point} (
                    {bet.ourBet?.details.price > 0 ? "+" : null}
                    {bet.ourBet?.details.price})
                  </p>
                  <div
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",

                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <p
                      style={{ fontFamily: "'Baloo Bhaijaan', cursive", margin: 0, marginTop: 10 }}>
                      {momentTime}
                    </p>
                    <p
                      style={{ fontFamily: "'Baloo Bhaijaan', cursive", margin: 0, marginTop: 10 }}>
                      {bet.ourBet?.note}
                    </p>
                  </div>{" "}
                  <div
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",

                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",

                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          textAlign: "left",
                          margin: 0,
                          marginTop: 2,
                        }}>
                        {bet.awayTeamAbbr}
                      </p>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          textAlign: "left",
                          margin: 0,
                          marginTop: 2,
                        }}>
                        {bet.homeTeamAbbr}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",

                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",

                          display: "flex",
                          flexDirection: "row",
                        }}>
                        {awayTeamLineScore.map((x) => (
                          <p
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 8,
                            }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}>
                          {score?.competitions[0].competitors[1].score}
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",

                          display: "flex",
                          flexDirection: "row",
                        }}>
                        {homeTeamLineScore.map((x) => (
                          <p
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 8,
                            }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}>
                          {score?.competitions[0].competitors[0].score}
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            } else if (bet.ourBet?.type === "spread") {
              const winner =
                bet.ourBet?.outcome === score?.competitions[0].competitors[1].team.displayName
                  ? "away"
                  : "home";
              const won =
                winner === "home"
                  ? homeTeamScore + bet.ourBet?.details.point > awayTeamScore
                  : homeTeamScore + bet.ourBet?.details.point < awayTeamScore;
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{
                    fontFamily: "'Baloo Bhaijaan', cursive",

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    border: won ? "1px solid green" : "1px solid red",
                    backgroundColor: won ? "green" : "red",
                    padding: "25px 0px",
                    color: "white",
                  }}
                  onClick={() => {}}>
                  <p
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      margin: 0,
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                    }}>
                    {bet.ourBet?.outcome}
                  </p>
                  <p
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      margin: 0,
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}>
                    {bet.ourBet?.details.point > 0 ? "+" : null}
                    {bet.ourBet?.details.point} ({bet.ourBet?.details.price > 0 ? "+" : null}
                    {bet.ourBet?.details.price}){" "}
                  </p>
                  <div
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",

                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <p
                      style={{ fontFamily: "'Baloo Bhaijaan', cursive", margin: 0, marginTop: 10 }}>
                      {momentTime}
                    </p>
                    <p
                      style={{ fontFamily: "'Baloo Bhaijaan', cursive", margin: 0, marginTop: 10 }}>
                      {bet.ourBet?.note}
                    </p>
                  </div>
                  {addNote && noteID === bet.ourBet?.tempID && (
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",
                        color: "white",
                        backgroundColor: "white",
                      }}
                      value={note}
                      onChange={(e) => {
                        const value = e.target.value;
                        setNote(value);
                      }}
                    />
                  )}{" "}
                  <div
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",

                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",

                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          textAlign: "left",
                          margin: 0,
                          marginTop: 2,
                        }}>
                        {bet.awayTeamAbbr}
                      </p>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          textAlign: "left",
                          margin: 0,
                          marginTop: 2,
                        }}>
                        {bet.homeTeamAbbr}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",

                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",

                          display: "flex",
                          flexDirection: "row",
                        }}>
                        {awayTeamLineScore.map((x) => (
                          <p
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 8,
                            }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}>
                          {score?.competitions[0].competitors[1].score}
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",

                          display: "flex",
                          flexDirection: "row",
                        }}>
                        {homeTeamLineScore.map((x) => (
                          <p
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 8,
                            }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}>
                          {score?.competitions[0].competitors[0].score}
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            } else {
              const winner =
                bet.ourBet?.outcome === score?.competitions[0].competitors[1].team.displayName
                  ? "away"
                  : "home";
              const won =
                winner === "home" ? homeTeamScore > awayTeamScore : homeTeamScore < awayTeamScore;
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{
                    fontFamily: "'Baloo Bhaijaan', cursive",

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    border: won ? "1px solid green" : "1px solid red",
                    backgroundColor: won ? "green" : "red",
                    padding: "25px 0px",
                    color: "white",
                  }}
                  onClick={() => {}}>
                  <p
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      margin: 0,
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                    }}>
                    {bet.ourBet?.outcome}
                  </p>
                  <p
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      margin: 0,
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}>
                    ML {bet.ourBet?.details > 0 ? "+" : null}
                    {bet.ourBet?.details}
                  </p>
                  <div
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",

                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <p
                      style={{ fontFamily: "'Baloo Bhaijaan', cursive", margin: 0, marginTop: 10 }}>
                      {momentTime}
                    </p>
                    <p
                      style={{ fontFamily: "'Baloo Bhaijaan', cursive", margin: 0, marginTop: 10 }}>
                      {bet.ourBet?.note}
                    </p>
                  </div>{" "}
                  <div
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",

                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",

                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          textAlign: "left",
                          margin: 0,
                          marginTop: 2,
                        }}>
                        {bet.awayTeamAbbr}
                      </p>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          textAlign: "left",
                          margin: 0,
                          marginTop: 2,
                        }}>
                        {bet.homeTeamAbbr}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",

                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",

                          display: "flex",
                          flexDirection: "row",
                        }}>
                        {awayTeamLineScore.map((x) => (
                          <p
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 8,
                            }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}>
                          {score?.competitions[0].competitors[1].score}
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",

                          display: "flex",
                          flexDirection: "row",
                        }}>
                        {homeTeamLineScore.map((x) => (
                          <p
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 8,
                            }}>
                            {x}
                          </p>
                        ))}
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 2,
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}>
                          {score?.competitions[0].competitors[0].score}
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            }
          })}
      </Grid>
      {selectedBets.length > 0 && (
        <Typography
          component="h2"
          variant="h4"
          color="primary"
          style={{ fontFamily: "'Baloo Bhaijaan', cursive", marginTop: 20 }}>
          Current Selected Bets
        </Typography>
      )}
      {selectedBets.length > 0 && (
        <Grid container style={{ fontFamily: "'Baloo Bhaijaan', cursive" }}>
          {selectedBets.length > 0 &&
            selectedBets
              .sort((a, b) => a.ourBet.tempID - b.ourBet.tempID)
              .map((bet) => {
                const shortDetail = bet.details?.type?.shortDetail;
                const time =
                  bet.details?.type?.name === "STATUS_SCHEDULED"
                    ? shortDetail.split("- ")[1].split(" E")[0]
                    : "";
                const momentTime =
                  bet.details?.type?.name === "STATUS_SCHEDULED"
                    ? moment(time, "h:mm a").subtract(1, "hour").format("h:mm a") + " CST"
                    : "";
                if (bet.ourBet?.type === "overUnder") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",

                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        border: "1px solid green",
                        padding: "25px 0px",
                        backgroundColor: "green",
                        color: "white",
                      }}>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          margin: 0,
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}>
                        {bet.awayTeam.name}
                      </p>
                      {" vs "}
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          margin: 0,
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}>
                        {bet.homeTeam.name}
                      </p>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          margin: 0,
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}>
                        {bet.ourBet?.outcome} {bet.ourBet?.details.point} (
                        {bet.ourBet?.details.price > 0 ? "+" : null}
                        {bet.ourBet?.details.price})
                      </p>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",

                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 10,
                          }}>
                          {momentTime}
                        </p>
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 10,
                          }}>
                          {bet.ourBet?.note}
                        </p>
                      </div>
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            color: "white",
                            backgroundColor: "white",
                          }}
                          value={note}
                          onChange={(e) => {
                            const value = e.target.value;
                            setNote(value);
                          }}
                        />
                      )}
                      {/* {!addNote && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            setAddNote(true);
                            setNoteID(bet.ourBet?.tempID);
                          }}>
                          Add Note
                        </Button>
                      )} */}
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            const betToUpdate = selectedBets.find(
                              (x) => x.ourBet.tempID === bet.ourBet?.tempID
                            );
                            betToUpdate.ourBet.note = note;
                            const restOfBets = selectedBets.filter(
                              (x) => x.ourBet.tempID !== bet.ourBet?.tempID
                            );
                            setSelectedBets([...restOfBets, betToUpdate]);
                            setNote("");
                            setAddNote(false);
                          }}>
                          Save Note
                        </Button>
                      )}
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        component="a"
                        sx={{ marginTop: 2 }}
                        onClick={() =>
                          setSelectedBets([
                            ...selectedBets.filter((x) => x.ourBet.tempID !== bet.ourBet?.tempID),
                          ])
                        }>
                        Remove Bet
                      </Button>
                    </Grid>
                  );
                } else if (bet.ourBet?.type === "spread") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        border: "1px solid green",
                        padding: "25px 0px",
                        backgroundColor: "green",
                        color: "white",
                      }}
                      onClick={() => {}}>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          margin: 0,
                          fontSize: "1.8rem",
                          fontWeight: "bold",
                        }}>
                        {bet.ourBet?.outcome}
                      </p>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          margin: 0,
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}>
                        {bet.ourBet?.details.point > 0 ? "+" : null}
                        {bet.ourBet?.details.point} ({bet.ourBet?.details.price > 0 ? "+" : null}
                        {bet.ourBet?.details.price}){" "}
                      </p>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",

                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 10,
                          }}>
                          {momentTime}
                        </p>
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 10,
                          }}>
                          {bet.ourBet?.note}
                        </p>
                      </div>
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            color: "white",
                            backgroundColor: "white",
                          }}
                          value={note}
                          onChange={(e) => {
                            const value = e.target.value;
                            setNote(value);
                          }}
                        />
                      )}
                      {/* {!addNote && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            setAddNote(true);
                            setNoteID(bet.ourBet?.tempID);
                          }}>
                          Add Note
                        </Button>
                      )} */}
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            const betToUpdate = selectedBets.find(
                              (x) => x.ourBet.tempID === bet.ourBet?.tempID
                            );
                            betToUpdate.ourBet.note = note;
                            const restOfBets = selectedBets.filter(
                              (x) => x.ourBet.tempID !== bet.ourBet?.tempID
                            );
                            setSelectedBets([...restOfBets, betToUpdate]);
                            setNote("");
                            setAddNote(false);
                          }}>
                          Save Note
                        </Button>
                      )}
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        component="a"
                        sx={{ marginTop: 2 }}
                        onClick={() =>
                          setSelectedBets([
                            ...selectedBets.filter((x) => x.ourBet.tempID !== bet.ourBet?.tempID),
                          ])
                        }>
                        Remove Bet
                      </Button>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",

                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        border: "1px solid green",
                        padding: "25px 0px",
                        backgroundColor: "green",
                        color: "white",
                      }}
                      onClick={() => {}}>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          margin: 0,
                          fontSize: "1.8rem",
                          fontWeight: "bold",
                        }}>
                        {bet.ourBet?.outcome}
                      </p>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          margin: 0,
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}>
                        ML {bet.ourBet?.details > 0 ? "+" : null}
                        {bet.ourBet?.details}
                      </p>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",

                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 10,
                          }}>
                          {momentTime}
                        </p>
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            margin: 0,
                            marginTop: 10,
                          }}>
                          {bet.ourBet?.note}
                        </p>
                      </div>
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            color: "white",
                            backgroundColor: "white",
                          }}
                          value={note}
                          onChange={(e) => {
                            const value = e.target.value;
                            setNote(value);
                          }}
                        />
                      )}
                      {/* {!addNote && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            setAddNote(true);
                            setNoteID(bet.ourBet?.tempID);
                          }}>
                          Add Note
                        </Button>
                      )} */}
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            const betToUpdate = selectedBets.find(
                              (x) => x.ourBet.tempID === bet.ourBet?.tempID
                            );
                            betToUpdate.ourBet.note = note;
                            const restOfBets = selectedBets.filter(
                              (x) => x.ourBet.tempID !== bet.ourBet?.tempID
                            );
                            setSelectedBets([...restOfBets, betToUpdate]);
                            setNote("");
                            setAddNote(false);
                          }}>
                          Save Note
                        </Button>
                      )}
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        component="a"
                        sx={{ marginTop: 2 }}
                        onClick={() =>
                          setSelectedBets([
                            ...selectedBets.filter((x) => x.ourBet.tempID !== bet.ourBet?.tempID),
                          ])
                        }>
                        Remove Bet
                      </Button>
                    </Grid>
                  );
                }
              })}
        </Grid>
      )}
      {selectedBets.length > 0 && (
        <div
          style={{
            fontFamily: "'Baloo Bhaijaan', cursive",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignContent: "center",
            textAlign: "center",
            width: "100%",
            marginTop: 20,
          }}>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component="a"
            sx={{ width: 300 }}
            onClick={() => {
              handleSaveBets();
            }}>
            Save Current Selected Bets
          </Button>
        </div>
      )}
      <Typography
        component="h2"
        variant="h4"
        color="primary"
        style={{ fontFamily: "'Baloo Bhaijaan', cursive", marginTop: 20 }}>
        All Games Today
      </Typography>
      <Grid container style={{ fontFamily: "'Baloo Bhaijaan', cursive", marginTop: 0 }}>
        {isLoading && (
          <div
            style={{
              fontFamily: "'Baloo Bhaijaan', cursive",
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
              alignContent: "center",
              alignItems: "center",
              color: "white",
            }}>
            <ReactLoading type={"spin"} color={"green"} height={150} width={150} />
          </div>
        )}
        {!isLoading
          ? bestBets
              ?.map((game) => {
                const homeDiff = Math.abs(
                  parseFloat(game.homeTeam.predictor?.gameProjection) -
                    parseFloat(game.homeTeam.predictor?.teamChanceLoss)
                );
                const awayDiff = Math.abs(
                  parseFloat(game.awayTeam.predictor?.gameProjection) -
                    parseFloat(game.awayTeam.predictor?.teamChanceLoss)
                );
                return { ...game, avgDiff: (homeDiff + awayDiff) / 2 };
              })
              .sort((a, b) => b.avgDiff - a.avgDiff)
              .map((bet) => {
                const shortDetail = bet.details?.type?.shortDetail;
                const time =
                  bet.details?.type?.name === "STATUS_SCHEDULED"
                    ? shortDetail.split("- ")[1].split(" E")[0]
                    : "";
                const momentTime =
                  bet.details?.type?.name === "STATUS_SCHEDULED"
                    ? moment(time, "h:mm a").subtract(1, "hour").format("h:mm a") + " CST"
                    : "";
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      padding: "25px 0px",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      backgroundColor: "black",
                      alignItems: "center",
                      color: "white",
                    }}>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 20,
                      }}>
                      <p
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          margin: 0,
                          marginTop: 0,
                        }}>
                        {momentTime}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",

                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "flex-start",
                      }}>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}>
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            marginBottom: 5,
                            fontSize: 25,
                            color:
                              parseFloat(
                                coversDotComPredictions.find((x) => x.team === bet.awayTeam.abbr)
                                  ?.percentage
                              ) > 70
                                ? "lightgreen"
                                : parseFloat(
                                    coversDotComPredictions.find(
                                      (x) => x.team === bet.awayTeam.abbr
                                    )?.percentage
                                  ) > 50
                                ? "yellow"
                                : "red",
                          }}>
                          {
                            coversDotComPredictions.find((x) => x.team === bet.awayTeam.abbr)
                              ?.percentage
                          }
                        </p>
                        {bet.awayTeam.predictor && (
                          <p
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              lineHeight: 1,
                              margin: 0,
                              marginBottom: 5,
                              fontSize: 25,
                              color:
                                parseFloat(bet.awayTeam.predictor?.gameProjection) -
                                  parseFloat(bet.awayTeam.predictor?.teamChanceLoss) >
                                30
                                  ? "lightgreen"
                                  : parseFloat(bet.awayTeam.predictor?.gameProjection) -
                                      parseFloat(bet.awayTeam.predictor?.teamChanceLoss) >
                                    20
                                  ? "yellow"
                                  : "red",
                            }}>
                            {bet.awayTeam.predictor?.gameProjection.split(".")[0]}%
                          </p>
                        )}
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{
                                  fontFamily: "'Baloo Bhaijaan', cursive",
                                  margin: 0,
                                  color: "white",
                                  padding: 0,
                                }}
                              />
                            }
                            onClick={() =>
                              setSelectedBets([
                                ...selectedBets,
                                {
                                  ourBet: {
                                    tempID: selectedBets.length + 1,
                                    outcome: bet.awayTeam.name,
                                    type: "moneyline",
                                    note: "",
                                    details: bet.awayTeam.odds.moneyline,
                                  },
                                  ...bet,
                                },
                              ])
                            }
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              margin: 0,
                              padding: 0,
                            }}
                            label={
                              bet.awayTeam.odds.moneyline > 0
                                ? "+" + bet.awayTeam.odds.moneyline
                                : bet.awayTeam.odds.moneyline
                            }
                          />
                          <FormControl
                            fullWidth
                            style={{ marginBottom: 10, marginTop: 10, width: 100 }}>
                            {/* <InputLabel id="demo-simple-select-label">Spread</InputLabel> */}
                            <Select
                              value={bet.awayTeam.odds.spread?.point}
                              label="Spread"
                              onChange={() => {}}
                              style={{ backgroundColor: "white" }}>
                              {getSpreadItems("away", bet.awayTeam.odds.spread?.point, bet)}
                            </Select>
                          </FormControl>
                          {/* {bet.awayTeam.odds.spread?.point && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{
                                    fontFamily: "'Baloo Bhaijaan', cursive",
                                    margin: 0,
                                    color: "white",
                                    padding: 0,
                                  }}
                                />
                              }
                              onClick={() =>
                                setSelectedBets([
                                  ...selectedBets,
                                  {
                                    ourBet: {
                                      tempID: selectedBets.length + 1,
                                      note: "",
                                      outcome: bet.awayTeam.name,
                                      type: "spread",
                                      details: bet.awayTeam.odds.spread,
                                    },
                                    ...bet,
                                  },
                                ])
                              }
                              style={{
                                fontFamily: "'Baloo Bhaijaan', cursive",
                                margin: 0,
                                padding: 0,
                              }}
                              label={`${bet.awayTeam.odds.spread?.point} (${
                                bet.awayTeam.odds.spread?.price > 0 ? "+" : ""
                              }${bet.awayTeam.odds.spread?.price})`}
                            />
                          )} */}
                        </FormGroup>
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            fontSize: 20,
                          }}>
                          {bet.awayTeam.team?.location}
                        </p>
                        <p
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            fontSize: 20,
                          }}>
                          {bet.awayTeam.team?.name}
                        </p>
                        {/* {bet.awayTeam.lastFiveGames
                          ?.map((x) => (
                            <div
                              style={{
                                fontFamily: "'Baloo Bhaijaan', cursive",
                                lineHeight: 1,
                                display: "flex",
                                margin: 0,
                                fontSize: "0.9rem",
                                marginTop: 2,
                                width: "100%",
                                backgroundColor:
                                  x.gameResult === "W" ? colors.lightGreen : colors.lightRed,
                              }}>
                              <p
                                style={{
                                  fontFamily: "'Baloo Bhaijaan', cursive",
                                  lineHeight: 1,
                                  margin: 0,
                                  fontSize: "0.9rem",
                                  width: "100%",
                                }}>
                                {x.gameResult}
                              </p>
                              <p
                                style={{
                                  fontFamily: "'Baloo Bhaijaan', cursive",
                                  lineHeight: 1,
                                  margin: 0,
                                  fontSize: "0.9rem",
                                  width: "100%",
                                }}>
                                {x.opponent.abbreviation}
                              </p>
                              <p
                                style={{
                                  fontFamily: "'Baloo Bhaijaan', cursive",
                                  lineHeight: 1,
                                  margin: 0,
                                  fontSize: "0.9rem",
                                  width: "100%",
                                }}>
                                {x.score}
                              </p>
                            </div>
                          ))
                          .reverse()} */}
                        {/* <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                          width: "100%",
                          backgroundColor:
                            parseInt(bet.awayTeam.overallRecord.split("-")[0]) > parseInt(bet.awayTeam.overallRecord.split("-")[1])
                              ? colors.lightGreen
                              : colors.lightRed,
                        }}>
                        Overall: {bet.awayTeam.overallRecord}
                      </p>
                      <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                          width: "100%",
                          backgroundColor:
                            parseInt(bet.awayTeam.awayRecord.split("-")[0]) > parseInt(bet.awayTeam.awayRecord.split("-")[1])
                              ? colors.lightGreen
                              : colors.lightRed,
                        }}>
                        Away: {bet.awayTeam.awayRecord}
                      </p>
                      <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                          width: "100%",
                          backgroundColor:
                            parseInt(bet.awayTeam.last10.split("-")[0]) > parseInt(bet.awayTeam.last10.split("-")[1])
                              ? colors.lightGreen
                              : colors.lightRed,
                        }}>
                        Last 10: {bet.awayTeam.last10}
                      </p>
                      <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                          width: "100%",
                          backgroundColor:
                            parseInt(bet.awayTeam.last20.split("-")[0]) > parseInt(bet.awayTeam.last20.split("-")[1])
                              ? colors.lightGreen
                              : colors.lightRed,
                        }}>
                        Last 20: {bet.awayTeam.last20}
                      </p> */}
                        {/* <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                          width: "100%",
                          color: bet.awayTeam.recordVsOpponent?.overall.win === bet.awayTeam.recordVsOpponent?.overall.loss ? "black" : "white",
                          backgroundColor:
                            bet.awayTeam.recordVsOpponent?.overall.win > bet.awayTeam.recordVsOpponent?.overall.loss
                              ? colors.lightGreen
                              : bet.awayTeam.recordVsOpponent?.overall.win === bet.awayTeam.recordVsOpponent?.overall.loss
                              ? colors.yellow
                              : colors.lightRed,
                        }}>
                        vs {bet.homeTeam.name}: {bet.awayTeam.recordVsOpponent?.overall.win} - {bet.awayTeam.recordVsOpponent?.overall.loss}
                      </p>
                      <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1,
                          margin: 0,
                          fontSize: 17,
                          marginTop: 2,
                        }}>
                        {bet.awayTeam.startingPitcher.name}
                      </p> */}
                        {/* <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}>
                        {bet.awayTeam.startingPitcher.wins}-{bet.awayTeam.startingPitcher.losses} {bet.awayTeam.startingPitcher.ERA} ERA
                      </p>
                      <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1.2,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}>
                        Last 3 Games
                      </p>
                      {bet.awayTeam.startingPitcher?.stats
                        ?.slice(-3)
                        .reverse()
                        .map((game, idx) => {
                          return (
                            <p
                              key={idx}
                              style={{fontFamily:"'Baloo Bhaijaan', cursive",
                                lineHeight: 1.5,
                                margin: 0,
                                fontSize: "0.7rem",
                                marginTop: 2,
                                width: "100%",
                                color: game.stat.wins > 0 || game.stat.losses > 0 ? "white" : "black",
                                backgroundColor: game.stat.wins > 0 ? colors.lightGreen : game.stat.losses > 0 ? colors.lightRed : colors.yellow,
                              }}>
                              {moment(game.date).format("MMM D")}: {game.stat.wins > 0 ? "W" : game.stat.losses > 0 ? "L" : "ND"} {game.stat.summary}
                            </p>
                          );
                        })}
                      {bet.awayTeam.startingPitcher?.stats?.filter((game) => game.opponent.name === bet.homeTeam.name).length > 0 && (
                        <p
                          style={{fontFamily:"'Baloo Bhaijaan', cursive",
                            lineHeight: 1.2,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}>
                          Against {bet.homeTeam.name}
                        </p>
                      )}
                      {bet.awayTeam.startingPitcher?.stats
                        ?.filter((game) => game.opponent.name === bet.homeTeam.name)
                        ?.map((game, idx) => {
                          return (
                            <p
                              key={idx}
                              style={{fontFamily:"'Baloo Bhaijaan', cursive",
                                lineHeight: 1.5,
                                margin: 0,
                                fontSize: "0.7rem",
                                marginTop: 2,
                                width: "100%",
                                color: game.stat.wins > 0 || game.stat.losses > 0 ? "white" : "black",
                                backgroundColor: game.stat.wins > 0 ? colors.lightGreen : game.stat.losses > 0 ? colors.lightRed : colors.yellow,
                              }}>
                              {moment(game.date).format("MMM D")}: {game.stat.wins > 0 ? "W" : game.stat.losses > 0 ? "L" : "ND"} {game.stat.summary}
                            </p>
                          );
                        })} */}
                      </div>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "flex-start",
                          textAlign: "center",
                          width: 100,
                          margin: 10,
                        }}>
                        <FormControl fullWidth>
                          {/* <InputLabel id="demo-simple-select-label">Over</InputLabel> */}
                          <p style={{ margin: 0 }}>Over</p>
                          <Select
                            value={bet.overUnder?.find((x) => x.name === "Over").point}
                            label="Over"
                            onChange={() => {}}
                            style={{ backgroundColor: "white" }}>
                            {getOverUnderItems(
                              "over",
                              bet.overUnder?.find((x) => x.name === "Over").point,
                              bet
                            )}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                          {/* <InputLabel id="demo-simple-select-label">Under</InputLabel> */}
                          <p style={{ margin: 0 }}>Under</p>
                          <Select
                            value={bet.overUnder?.find((x) => x.name === "Under").point}
                            label="Under"
                            onChange={() => {}}
                            style={{ backgroundColor: "white" }}>
                            {getOverUnderItems(
                              "under",
                              bet.overUnder?.find((x) => x.name === "Under").point,
                              bet
                            )}
                          </Select>
                        </FormControl>
                        {/* <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1.2,
                          margin: 0,
                          fontSize: "0.9rem",
                          textAlign: "center",
                          marginTop: 2,
                        }}>
                        Predicted Points
                      </p>
                      <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1.2,
                          margin: 0,
                          fontSize: "0.9rem",
                          textAlign: "center",
                          marginTop: 2,
                        }}>
                        {bet.awayTeam.abbr}
                        {" - "}
                        {bet.predictedRunTotals.awayTeamPredictedPoints.toFixed(2)}
                      </p>
                      <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1.2,
                          margin: 0,
                          fontSize: "0.9rem",
                          textAlign: "center",
                          marginTop: 2,
                        }}>
                        {bet.homeTeam.abbr}
                        {" - "}
                        {bet.predictedRunTotals.homeTeamPredictedPoints.toFixed(2)}
                      </p>
                      <p
                        style={{fontFamily:"'Baloo Bhaijaan', cursive",
                          lineHeight: 1.2,
                          margin: 0,
                          fontSize: "0.9rem",
                          textAlign: "center",
                          marginTop: 2,
                        }}>
                        Total: {bet.predictedRunTotals.gameTotalPredictedPoints.toFixed(2)}
                      </p> */}
                      </div>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",

                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "flex-start",
                        }}>
                        <div
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",

                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              lineHeight: 1,
                              margin: 0,
                              marginBottom: 5,
                              fontSize: 25,
                              color:
                                parseFloat(
                                  coversDotComPredictions.find((x) => x.team === bet.homeTeam.abbr)
                                    ?.percentage
                                ) > 70
                                  ? "lightgreen"
                                  : parseFloat(
                                      coversDotComPredictions.find(
                                        (x) => x.team === bet.homeTeam.abbr
                                      )?.percentage
                                    ) > 50
                                  ? "yellow"
                                  : "red",
                            }}>
                            {
                              coversDotComPredictions.find((x) => x.team === bet.homeTeam.abbr)
                                ?.percentage
                            }
                          </p>
                          {bet.homeTeam.predictor && (
                            <p
                              style={{
                                fontFamily: "'Baloo Bhaijaan', cursive",
                                lineHeight: 1,
                                margin: 0,
                                marginBottom: 5,
                                fontSize: 25,
                                color:
                                  parseFloat(bet.homeTeam.predictor?.gameProjection) -
                                    parseFloat(bet.homeTeam.predictor?.teamChanceLoss) >
                                  30
                                    ? "lightgreen"
                                    : parseFloat(bet.homeTeam.predictor?.gameProjection) -
                                        parseFloat(bet.homeTeam.predictor?.teamChanceLoss) >
                                      20
                                    ? "yellow"
                                    : "red",
                              }}>
                              {bet.homeTeam.predictor?.gameProjection.split(".")[0]}%
                            </p>
                          )}
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{
                                    fontFamily: "'Baloo Bhaijaan', cursive",
                                    margin: 0,
                                    color: "white",
                                    padding: 0,
                                  }}
                                />
                              }
                              onClick={() =>
                                setSelectedBets([
                                  ...selectedBets,
                                  {
                                    ourBet: {
                                      tempID: selectedBets.length + 1,
                                      note: "",
                                      outcome: bet.homeTeam.name,
                                      type: "moneyline",
                                      details: bet.homeTeam.odds.moneyline,
                                    },
                                    ...bet,
                                  },
                                ])
                              }
                              style={{
                                fontFamily: "'Baloo Bhaijaan', cursive",
                                margin: 0,
                                padding: 0,
                              }}
                              label={
                                bet.homeTeam.odds.moneyline > 0
                                  ? "+" + bet.homeTeam.odds.moneyline
                                  : bet.homeTeam.odds.moneyline
                              }
                            />
                            <FormControl
                              fullWidth
                              style={{ marginBottom: 10, marginTop: 10, width: 100 }}>
                              {/* <InputLabel id="demo-simple-select-label">Spread</InputLabel> */}
                              <Select
                                value={bet.homeTeam.odds.spread?.point}
                                label="Spread"
                                onChange={() => {}}
                                style={{ backgroundColor: "white" }}>
                                {getSpreadItems("home", bet.homeTeam.odds.spread?.point, bet)}
                              </Select>
                            </FormControl>
                            {/* {bet.homeTeam.odds.spread?.point && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    style={{
                                      fontFamily: "'Baloo Bhaijaan', cursive",
                                      margin: 0,
                                      color: "white",
                                      padding: 0,
                                    }}
                                  />
                                }
                                onClick={() =>
                                  setSelectedBets([
                                    ...selectedBets,
                                    {
                                      ourBet: {
                                        tempID: selectedBets.length + 1,
                                        outcome: bet.homeTeam.name,
                                        type: "spread",
                                        note: "",
                                        details: bet.homeTeam.odds.spread,
                                      },
                                      ...bet,
                                    },
                                  ])
                                }
                                style={{
                                  fontFamily: "'Baloo Bhaijaan', cursive",
                                  margin: 0,
                                  padding: 0,
                                }}
                                label={`${bet.homeTeam.odds.spread?.point} (${
                                  bet.homeTeam.odds.spread?.price > 0 ? "+" : ""
                                }${bet.homeTeam.odds.spread?.price})`}
                              />
                            )} */}
                          </FormGroup>
                          <p
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              lineHeight: 1,
                              margin: 0,
                              fontSize: 20,
                            }}>
                            {bet.homeTeam.team?.location}
                          </p>
                          <p
                            style={{
                              fontFamily: "'Baloo Bhaijaan', cursive",
                              lineHeight: 1,
                              margin: 0,
                              fontSize: 20,
                            }}>
                            {bet.homeTeam.team?.name}
                          </p>

                          {/* {bet.homeTeam.lastFiveGames?.map((x) => (
                            <div
                              style={{
                                fontFamily: "'Baloo Bhaijaan', cursive",
                                lineHeight: 1,
                                display: "flex",
                                margin: 0,
                                fontSize: "0.9rem",
                                marginTop: 2,
                                width: "100%",
                                backgroundColor:
                                  x.gameResult === "W" ? colors.lightGreen : colors.lightRed,
                              }}>
                              <p
                                style={{
                                  fontFamily: "'Baloo Bhaijaan', cursive",
                                  lineHeight: 1,
                                  margin: 0,
                                  fontSize: "0.9rem",
                                  width: "100%",
                                }}>
                                {x.gameResult}
                              </p>
                              <p
                                style={{
                                  fontFamily: "'Baloo Bhaijaan', cursive",
                                  lineHeight: 1,
                                  margin: 0,
                                  fontSize: "0.9rem",
                                  width: "100%",
                                }}>
                                {x.opponent.abbreviation}
                              </p>
                              <p
                                style={{
                                  fontFamily: "'Baloo Bhaijaan', cursive",
                                  lineHeight: 1,
                                  margin: 0,
                                  fontSize: "0.9rem",
                                  width: "100%",
                                }}>
                                {x.score}
                              </p>
                            </div>
                          ))} */}
                          {/* <p
                          style={{fontFamily:"'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                            width: "100%",
                            backgroundColor: bet.homeTeam.currentStreak > 0 ? colors.lightGreen : colors.lightRed,
                          }}>
                          Streak:{" "}
                          {bet.homeTeam.currentStreak > 0
                            ? "W" + bet.homeTeam.currentStreak
                            : "L" + bet.homeTeam.currentStreak.toString().split("-")[1]}
                        </p>
                        <p
                          style={{fontFamily:"'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                            width: "100%",
                            backgroundColor:
                              parseInt(bet.homeTeam.overallRecord.split("-")[0]) > parseInt(bet.homeTeam.overallRecord.split("-")[1])
                                ? colors.lightGreen
                                : colors.lightRed,
                          }}>
                          Overall: {bet.homeTeam.overallRecord}
                        </p>
                        <p
                          style={{fontFamily:"'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                            width: "100%",
                            backgroundColor:
                              parseInt(bet.homeTeam.awayRecord.split("-")[0]) > parseInt(bet.homeTeam.awayRecord.split("-")[1])
                                ? colors.lightGreen
                                : colors.lightRed,
                          }}>
                          Away: {bet.homeTeam.awayRecord}
                        </p>
                        <p
                          style={{fontFamily:"'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                            width: "100%",
                            backgroundColor:
                              parseInt(bet.homeTeam.last10.split("-")[0]) > parseInt(bet.homeTeam.last10.split("-")[1])
                                ? colors.lightGreen
                                : colors.lightRed,
                          }}>
                          Last 10: {bet.homeTeam.last10}
                        </p>
                        <p
                          style={{fontFamily:"'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                            width: "100%",
                            backgroundColor:
                              parseInt(bet.homeTeam.last20.split("-")[0]) > parseInt(bet.homeTeam.last20.split("-")[1])
                                ? colors.lightGreen
                                : colors.lightRed,
                          }}>
                          Last 20: {bet.homeTeam.last20}
                        </p>
                        <p
                          style={{fontFamily:"'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                            color: bet.homeTeam.recordVsOpponent?.overall.win === bet.homeTeam.recordVsOpponent?.overall.loss ? "black" : "white",
                            backgroundColor:
                              bet.homeTeam.recordVsOpponent?.overall.win > bet.homeTeam.recordVsOpponent?.overall.loss
                                ? colors.lightGreen
                                : bet.homeTeam.recordVsOpponent?.overall.win === bet.homeTeam.recordVsOpponent?.overall.loss
                                ? colors.yellow
                                : colors.lightRed,
                          }}>
                          vs {bet.awayTeam.name}: {bet.homeTeam.recordVsOpponent?.overall.win} - {bet.homeTeam.recordVsOpponent?.overall.loss}
                        </p>
                        <p
                          style={{fontFamily:"'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            fontSize: 17,
                            marginTop: 2,
                          }}>
                          {bet.homeTeam.startingPitcher.name}
                        </p>
                        <p
                          style={{fontFamily:"'Baloo Bhaijaan', cursive",
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}>
                          {bet.homeTeam.startingPitcher.wins}-{bet.homeTeam.startingPitcher.losses} {bet.homeTeam.startingPitcher.ERA} ERA
                        </p>
                        <p
                          style={{fontFamily:"'Baloo Bhaijaan', cursive",
                            lineHeight: 1.2,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}>
                          Last 3 Games
                        </p>
                        {bet.homeTeam.startingPitcher?.stats
                          ?.slice(-3)
                          .reverse()
                          .map((game, idx) => {
                            return (
                              <p
                                key={idx}
                                style={{fontFamily:"'Baloo Bhaijaan', cursive",
                                  lineHeight: 1.5,
                                  margin: 0,
                                  fontSize: "0.7rem",
                                  marginTop: 2,
                                  width: "100%",
                                  color: game.stat.wins > 0 || game.stat.losses > 0 ? "white" : "black",
                                  backgroundColor: game.stat.wins > 0 ? colors.lightGreen : game.stat.losses > 0 ? colors.lightRed : colors.yellow,
                                }}>
                                {moment(game.date).format("MMM D")}: {game.stat.wins > 0 ? "W" : game.stat.losses > 0 ? "L" : "ND"}{" "}
                                {game.stat.summary}
                              </p>
                            );
                          })}
                        {bet.homeTeam.startingPitcher?.stats?.filter((game) => game.opponent.name === bet.awayTeam.name).length > 0 && (
                          <p
                            style={{fontFamily:"'Baloo Bhaijaan', cursive",
                              lineHeight: 1.2,
                              margin: 0,
                              fontSize: "0.9rem",
                              marginTop: 2,
                            }}>
                            Against {bet.awayTeam.name}
                          </p>
                        )}
                        {bet.homeTeam.startingPitcher?.stats
                          ?.filter((game) => game.opponent.name === bet.awayTeam.name)
                          ?.map((game, idx) => {
                            return (
                              <p
                                key={idx}
                                style={{fontFamily:"'Baloo Bhaijaan', cursive",
                                  lineHeight: 1.5,
                                  margin: 0,
                                  fontSize: "0.7rem",
                                  marginTop: 2,
                                  width: "100%",
                                  color: game.stat.wins > 0 || game.stat.losses > 0 ? "white" : "black",
                                  backgroundColor: game.stat.wins > 0 ? colors.lightGreen : game.stat.losses > 0 ? colors.lightRed : colors.yellow,
                                }}>
                                {moment(game.date).format("MMM D")}: {game.stat.wins > 0 ? "W" : game.stat.losses > 0 ? "L" : "ND"}{" "}
                                {game.stat.summary}
                              </p>
                            );
                          })} */}
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              })
          : null}
      </Grid>
    </div>
  );
}

export default TodaysBets;
