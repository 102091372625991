import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../components/AppBar";
import Toolbar from "../components/Toolbar";
import { DataContext } from "../../context/DataContext";
import logo from "../assets/logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";

const rightLink = {
  fontSize: 16,
  color: "common.white",
  ml: 3,
};

const iconStyle = {
  width: 25,
  height: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "secondary.main",
  mr: 1,
  "&:hover": {
    bgcolor: "secondary.dark",
  },
  borderRadius: "20%",
};

function AppAppBar() {
  const { isLoggedIn, setisLoggedIn, account, setAccount } =
    React.useContext(DataContext);
  React.useEffect(() => {}, [isLoggedIn]);
  return (
    <div>
      <AppBar position="fixed" style={{ backgroundColor: "black" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Link
            variant="p"
            underline="none"
            color="inherit"
            href="/"
            sx={{
              fontSize: { xs: 25, sm: 30 },
              display: { xs: "flex" },
            }}
          >
            <img src={logo} alt="logo" style={{ width: 200 }} />
          </Link>
          {!isLoggedIn ? (
            <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
              <Box
                component="a"
                href="https://www.instagram.com/eliteedgeadvisors"
                sx={iconStyle}
              >
                <InstagramIcon />
              </Box>
              <Link
                color="inherit"
                variant="p"
                style={{ margin: 0 }}
                underline="none"
                href="/sign-in/"
                sx={rightLink}
              >
                {"Sign In"}
              </Link>
            </Box>
          ) : (
            <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
              <Box
                component="a"
                href="https://www.instagram.com/eliteedgeadvisors"
                sx={iconStyle}
              >
                <InstagramIcon />
              </Box>
              <Link
                color="inherit"
                variant="p"
                style={{ margin: 0 }}
                underline="none"
                href="/"
                sx={rightLink}
              >
                Log Out
              </Link>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
