import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPassword from "./modules/screens/ForgotPassword";
import App from "./App";
import SignUp from "./modules/screens/SignUp";
import PreviousBetsScreen from "./modules/screens/PreviousBetsScreen";
import SignIn from "./modules/screens/SignIn";
import Terms from "./modules/screens/Terms";
import AccountScreen from "./modules/screens/AccountScreen";
import AdminScreen from "./modules/screens/AdminScreen";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import MyProvider from "./context/DataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MyProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AccountScreen />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-in/:username/:password" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/account" element={<AccountScreen />} />
            <Route path="/previous-bets" element={<PreviousBetsScreen />} />
            <Route path="/admin" element={<AdminScreen />} />
          </Routes>
        </BrowserRouter>
      </MyProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
